import React from 'react'
import slide0 from './images/slide0.jpg'
import slide1 from './images/slide1.jpg'
import slide2 from './images/slide2.jpg'
import slide3 from './images/slide3.jpg'

const images = [slide0, slide1, slide2, slide3]

function Slideshow({ delay }) {
  const [index, setIndex] = React.useState(0);
  const timeoutRef = React.useRef(null);

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  React.useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(
      () =>
        setIndex((prevIndex) =>
          prevIndex === images.length - 1 ? 0 : prevIndex + 1
        ),
      delay
    );

    return () => {
      resetTimeout();
    };
  }, [index]);

  return (
    <div className="overflow-hidden">
      <div
        className="whitespace-nowrap ease-in-out duration-1000"
        style={{ transform: `translate3d(${-index * 100}%, 0, 0)` }}
      >
        {images.map((image, i) => (
            <img
              key={i}
              className="inline-block w-full"
              src={image}
              loading="eager"
            />
        ))}
      </div>
    </div>
  );
}

export default Slideshow