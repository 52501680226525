import React, { useState } from 'react';
import './InputTextarea.css';
import _ from 'lodash';

function InputTextarea({ label, value, onChange, rows, placeholder, disabled, type, width, textAlign }) {
  const [id] = useState(_.uniqueId("input_"))

  const style = {}
  if (width) {
    style.width = width;
    style.minWidth = width;
  }
  if (textAlign) {
    style.textAlign = textAlign
  }

  return (
    <div className="InputTextarea">
      <div>
        {!!label && <label htmlFor={id}>{label}&nbsp;</label>}
      </div>
      <textarea
        style={style}
        type={type ? type : "text"}
        value={value}
        rows={rows}
        placeholder={placeholder ? placeholder : undefined}
        onChange={e => onChange(e.target.value)}
        id={id}
        className="InputTextarea-textarea border-2 border-black rounded px-1"
        disabled={disabled ? "disabled" : undefined}
      />
    </div>
  )
}

export default InputTextarea;
