import React from 'react';
import './InputRadio.css';
import InputRadioOption from './InputRadioOption'

function InputRadio({ label, options, selectedIndexes, onChangeSelected, id, exclusive=false, innerRef, children }) {
  const handleSelect = i => {
    if (exclusive) {
      onChangeSelected([i])
    } else {
      onChangeSelected(
        selectedIndexes.includes(i)
          ? [...selectedIndexes.filter(e => e !== i)]
          : [...selectedIndexes, i]
      )
    }
  }

  return (
    <div className="InputRadio" ref={innerRef}>
      <p className="InputRadio-label">{label}</p>
      {options.map((v, i) => (
        <InputRadioOption
          key={i}
          checked={selectedIndexes.includes(i)}
          onClick={() => handleSelect(i)}
        >
          {v}
        </InputRadioOption>
      ))}
      {children}
    </div>
  )
}

export default InputRadio;
