import React, { useState } from 'react';
import './InputText.css';
import _ from 'lodash';

function InputText({ label, value, onChange, disabled, type, width, textAlign, onClick, inline=false }) {
  const [id] = useState(_.uniqueId("input_"))

  const style = {}
  if (width) {
    style.width = width;
    style.minWidth = width;
  }
  if (textAlign) {
    style.textAlign = textAlign
  }

  return (
    <div
      className="InputText"
      style={inline ? { display: 'inline' } : undefined}
    >
      {!!label && <label className="InputText-label" htmlFor={id}>{label}&nbsp;</label>}
      <input
        style={style}
        type={type ? type : "text"}
        value={value}
        onChange={e => onChange(e.target.value)}
        onClick={onClick}
        id={id}
        className="InputText-input border-b-2 border-black px-1"
        disabled={disabled ? "disabled" : undefined}
      />
    </div>
  )
}

export default InputText;
