import React, { useState, useRef } from 'react';
import './RequestQuote.css';
import Button from './Button'
import InputText from './InputText'
import InputTextarea from './InputTextarea'
import InputDropdown from './InputDropdown'
import InputRadio from './InputRadio'
import InputRadioOption from './InputRadioOption'

const yesNo = [
  "Select",
  "Yes",
  "No"
]

const services = [
  "House Wash",
  "Deck Restoration",
  "Driveway/Cement Cleaning",
  "Fence Restoration",
  "Gutter Whitening",
  "Graffiti Removal",
  "Bulk HOA Projects",
  "Commercial Project",
]
const sidingTypes = [
  "Vinyl",
  "Metal",
  "Brick",
  "Wood",
  "Stucco",
]
const houseTypes = [
  "Select",
  "1 story",
  "2 story",
  "3+ story",
]
const squareFootages = [
  "Select",
  "0-2000",
  "2000-3000",
  "3000+",
]
//Deck Restoration
const deckServices = [
  "Deck Cleaning",
  "Deck Stripping",
  "Deck Cleaning, Stripping, & Staining",
]
const deckSubstrates = [
  "Wood",
  "Composite",
]
const deckHeights = [
  "Select",
  "Ground Floor",
  "Second Story",
]
// Driveway/Cement Cleaning
const cleaningAreas = [
  "Driveway",
   "Doorstep",
   "Walkway",
   "Sidewalk",
   "Patio",
]
const drivewaySubstrates = [
  "Cement",
  "Pavers",
]
const quoteTypes = [
  "I want to take measurements myself and get an online quote",
  "I want Mighty Mist to take measurements for me",
]
//Fence Restoration
const fenceSubstrates = [
  "Wood",
  "Plastic",
]
const woodFenceServices = [
  "Fence Cleaning",
  "Fence Stripping",
  "Fence Cleaning, Stripping, & Staining",
]


function RequestQuote() {
  const [step, setStep] = useState(0)

  const [selectedServices, setSelectedServices] = useState([])

  const [quoteType, setQuoteType] = useState(-1)
  const [houseType, setHouseType] = useState('Select')
  const [squareFootage, setSquareFootage] = useState('Select')
  const [sidingType, setSidingType] = useState(-1)
  const [sidingTypeOther, setSidingTypeOther] = useState('')

  const [deckService, setDeckService] = useState(-1)
  const [deckServiceOther, setDeckServiceOther] = useState('')
  const [deckSubstrate, setDeckSubstrate] = useState(-1)
  const [deckSubstrateOther, setDeckSubstrateOther] = useState('')
  const [deckArea, setDeckArea] = useState('')
  const [railingsLen, setRailingsLen] = useState('')
  const [deckHeight, setDeckHeight] = useState('Select')
  const [deckIsStained, setDeckIsStained] = useState(0)
  const [deckStainType, setDeckStainType] = useState('')

  const [selectedCleaningAreas, setSelectedCleaningAreas] = useState([])
  const [drivewaySubstrate, setDrivewaySubstrate] = useState(-1)
  const [drivewaySubstrateOther, setDrivewaySubstrateOther] = useState('')
  const [drivewayArea, setDrivewayArea] = useState('')

  const [fenceSubstrate, setFenceSubstrate] = useState(-1)
  const [fenceSubstrateOther, setFenceSubstrateOther] = useState('')
  const [fenceService, setFenceService] = useState(-1)
  const [fenceServiceOther, setFenceServiceOther] = useState('')
  const [fenceIsStained, setFenceIsStained] = useState(0)
  const [fenceStainType, setFenceStainType] = useState('')
  const [fenceHeight, setFenceHeight] = useState('')
  const [fenceLength, setFenceLength] = useState('')

  const [graffitiDescription, setGraffitiDescription] = useState('')
  const [bulkHoaDescription, setBulkHoaDescription] = useState('')
  const [commercialProjectDescription, setCommercialProjectDescription] = useState('')

  const [name, setName] = useState('')
  const [address, setAddress] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [email, setEmail] = useState('')

  const [submitDisabled, setSubmitDisabled] = useState(false)
  const [submitError,    setSubmitError]    = useState(false)
  const [submitSuccess,  setSubmitSuccess]  = useState(false)

  const quoteTypeElem = useRef(null)

  const handleSelectQuoteType = idxs => {
    console.log(quoteTypeElem.current)
    setQuoteType(idxs[0])
    if (idxs.includes(0) && quoteTypeElem) {
      setTimeout(() => quoteTypeElem.current.scrollIntoView({ behavior: 'smooth' }), 100)
    }
  }

  const handleNext = () => {
    setStep(prev => prev + 1)
  }

  const handleBack = () => {
    setStep(prev => prev - 1)
  }

  const serviceIsSelected = service => selectedServices.includes(services.indexOf(service))

  const handleSubmit = () => {
    if (!name) {
      alert('Please enter your name.')
    } else if (!address) {
      alert('Please enter your address.')
    } else if (!phoneNumber && !email) {
      alert('Please enter your phone number or email.')
    } else {
      if (submitDisabled) return;
      setSubmitDisabled(true)

      const body = {}

      if (name)
        body.name = name
      if (address)
        body.address = address
      if (phoneNumber)
        body.phoneNumber = phoneNumber
      if (email)
        body.email = email

      body.selectedServices = selectedServices.map(idx => services[idx])

      if (quoteType > -1)
        body.quoteType = quoteTypes[quoteType]
      if (houseType)
        body.houseType = houseType
      if (squareFootage)
        body.squareFootage = squareFootage
      if (sidingType > -1)
        body.sidingType = sidingTypes[sidingType]
      if (sidingTypeOther)
        body.sidingTypeOther = sidingTypeOther

      if (deckService > -1)
        body.deckService = deckServices[deckService]
      if (deckServiceOther)
        body.deckServiceOther = deckServiceOther
      if (deckSubstrate > -1)
        body.deckSubstrate = deckSubstrates[deckSubstrate]
      if (deckSubstrateOther)
        body.deckSubstrateOther = deckSubstrateOther
      if (deckArea)
        body.deckArea = deckArea
      if (railingsLen)
        body.railingsLen = railingsLen
      if (deckHeight)
        body.deckHeight = deckHeight
      if (deckIsStained > 0)
        body.deckIsStained = yesNo[deckIsStained]
      if (deckStainType)
        body.deckStainType = deckStainType

      if (selectedCleaningAreas.length > 0)
        body.selectedCleaningAreas = selectedCleaningAreas.map(idx => cleaningAreas[idx])
      if (drivewaySubstrate > -1)
        body.drivewaySubstrate = drivewaySubstrates[drivewaySubstrate]
      if (drivewaySubstrateOther)
        body.drivewaySubstrateOther = drivewaySubstrateOther
      if (drivewayArea)
        body.drivewayArea = drivewayArea

      if (fenceSubstrate > -1)
        body.fenceSubstrate = fenceSubstrates[fenceSubstrate]
      if (fenceSubstrateOther)
        body.fenceSubstrateOther = fenceSubstrateOther
      if (fenceService > -1)
        body.fenceService = woodFenceServices[fenceService]
      if (fenceServiceOther)
        body.fenceServiceOther = fenceServiceOther
      if (fenceIsStained > 0)
        body.fenceIsStained = yesNo[fenceIsStained]
      if (fenceStainType)
        body.fenceStainType = fenceStainType
      if (fenceHeight)
        body.fenceHeight = fenceHeight
      if (fenceLength)
        body.fenceLength = fenceLength

      if (graffitiDescription)
        body.graffitiDescription = graffitiDescription
      if (bulkHoaDescription)
        body.bulkHoaDescription = bulkHoaDescription
      if (commercialProjectDescription)
        body.commercialProjectDescription = commercialProjectDescription

      fetch('/api/requestQuote', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
      })
      .then(response => {
        setSubmitDisabled(false)
        if (200 <= response.status && response.status < 300) {
          if (step === 0) {
            handleNext()
          } else {
            setSubmitSuccess(true)
            setSubmitError(false)
          }
        } else {
          setSubmitError(true)
          setSubmitSuccess(false)
        }
      })
      .catch(error => {
        setSubmitDisabled(false)
        setSubmitError(true)
        setSubmitSuccess(false)
        console.log(error)
      })
    }
  }

  const isSubmitStep = (step === 0 || step > 1)

  return (
    <div className="RequestQuote text-black">
      <div className="RequestQuote-form">
        <div className="RequestQuote-form-submit-status">
          {submitSuccess && (
            <>
              <p>Thanks For Choosing Mighty Mist!</p>
              <p>We have your request, and we’ll get back to you as soon as we can.</p>
            </>
          )}
          {submitError && (
            <p>An error occured, and your request failed to submit. Please give us a call instead.</p>
          )}
        </div>
        {!(submitSuccess || submitError) && (
          <div className="RequestQuote-form-contents">
            <div className="RequestQuote-form-contents-header">
              {step === 0 ? "Quote Request" : "Additional Info"}
            </div>
            <div className="RequestQuote-form-contents-body">
              {step === 0 && (
                <>
                  <div className="RequestQuote-input-row">
                    <InputText
                      label="Full Name:"
                      value={name}
                      onChange={value => setName(value)}
                    />
                  </div>
                  <div className="RequestQuote-input-row">
                    <InputText
                      label="Address:"
                      value={address}
                      onChange={value => setAddress(value)}
                    />
                  </div>
                  <div className="RequestQuote-input-row">
                    <InputText
                      label="Phone Number:"
                      value={phoneNumber}
                      onChange={value => setPhoneNumber(value)}
                    />
                  </div>
                  <div className="RequestQuote-input-row">
                    <InputText
                      type="email "
                      label="Email:"
                      value={email}
                      onChange={value => setEmail(value)}
                    />
                  </div>
                </>
              )}
              {step === 1 && (
                <InputRadio
                  label="Servics Requested (check all that apply):"
                  options={services}
                  selectedIndexes={selectedServices}
                  onChangeSelected={setSelectedServices}
                />
              )}
              {step === 2 && (serviceIsSelected("House Wash") || serviceIsSelected("Gutter Whitening")) && (
                <>
                  <div className="RequestQuote-input-row">
                    <InputDropdown
                      label="House Type:"
                      options={houseTypes}
                      selectedIdx={houseTypes.indexOf(houseType)}
                      onSelectIdx={i => setHouseType(houseTypes[i])}
                    />
                  </div>
                  <div className="RequestQuote-input-row">
                    <InputDropdown
                      label="Square Footage of Home:"
                      options={squareFootages}
                      selectedIdx={squareFootages.indexOf(squareFootage)}
                      onSelectIdx={i => setSquareFootage(squareFootages[i])}
                    />
                  </div>
                </>
              )}
              {step === 2 && serviceIsSelected("House Wash") && (
                <>
                  <div className="RequestQuote-input-row">
                    <InputRadio
                      label="Siding Type:"
                      options={sidingTypes}
                      exclusive={true}
                      selectedIndexes={[sidingType]}
                      onChangeSelected={idxs => setSidingType(idxs[0])}
                    >
                      <InputRadioOption
                        checked={sidingType === sidingTypes.length}
                        onClick={() => setSidingType(sidingTypes.length)}
                      >
                          Other&nbsp;
                          <InputText
                            value={sidingTypeOther}
                            onChange={setSidingTypeOther}
                            onClick={() => setSidingType(sidingTypes.length)}
                            width="7em"
                            inline={true}
                          />
                      </InputRadioOption>
                    </InputRadio>
                  </div>
                </>
              )}
              {step === 2 && serviceIsSelected("Deck Restoration") && (
                <div className="RequestQuote-input-row">
                  <InputRadio
                    label="Deck Service Requested:"
                    options={deckServices}
                    exclusive={true}
                    selectedIndexes={[deckService]}
                    onChangeSelected={idxs => setDeckService(idxs[0])}
                  >
                    <InputRadioOption
                      checked={deckService === deckServices.length}
                      onClick={() => setDeckService(deckServices.length)}
                    >
                        Other&nbsp;
                        <InputText
                          value={deckServiceOther}
                          onChange={setDeckServiceOther}
                          onClick={() => setDeckService(deckServices.length)}
                          width="7em"
                          inline={true}
                        />
                    </InputRadioOption>
                  </InputRadio>
                </div>
              )}
              {step === 2 && serviceIsSelected("Driveway/Cement Cleaning") && (
                <>
                  <div className="RequestQuote-input-row">
                    <InputRadio
                      label="Areas to be Cleaned (check all that apply):"
                      options={cleaningAreas}
                      selectedIndexes={selectedCleaningAreas}
                      onChangeSelected={setSelectedCleaningAreas}
                    />
                  </div>
                  <div className="RequestQuote-input-row">
                    <InputRadio
                      label="Driveway/Cement Substrate:"
                      options={drivewaySubstrates}
                      exclusive={true}
                      selectedIndexes={[drivewaySubstrate]}
                      onChangeSelected={idxs => setDrivewaySubstrate(idxs[0])}
                    >
                      <InputRadioOption
                        checked={drivewaySubstrate === drivewaySubstrates.length}
                        onClick={() => setDrivewaySubstrate(drivewaySubstrates.length)}
                      >
                          Other&nbsp;
                          <InputText
                            value={drivewaySubstrateOther}
                            onChange={setDrivewaySubstrateOther}
                            onClick={() => setDrivewaySubstrate(drivewaySubstrates.length)}
                            width="7em"
                            inline={true}
                          />
                      </InputRadioOption>
                    </InputRadio>
                  </div>
                </>
              )}
              {step === 2 && serviceIsSelected("Graffiti Removal") && (
                <div className="RequestQuote-input-row">
                  <InputTextarea
                    label={(
  `Please give a brief description of graffiti to be cleaned. Helpful info would be:
  	-Type of paint/ other
  	-Substrate
  	-Area that was vandalised`
                    )}
                    value={graffitiDescription}
                    onChange={setGraffitiDescription}
                    rows={4}
                  />
                </div>
              )}
              {step === 2 && serviceIsSelected("Bulk HOA Projects") && (
                <div className="RequestQuote-input-row">
                  <InputTextarea
                    label="Please give a brief description of the bulk HOA project:"
                    value={bulkHoaDescription}
                    onChange={setBulkHoaDescription}
                    rows={4}
                  />
                </div>
              )}
              {step === 2 && serviceIsSelected("Commercial Project") && (
                <div className="RequestQuote-input-row">
                  <InputTextarea
                    label="Please give a brief description of the commercial project:"
                    value={commercialProjectDescription}
                    onChange={setCommercialProjectDescription}
                    rows={4}
                  />
                </div>
              )}
              {step === 2 && (serviceIsSelected("Deck Restoration") || serviceIsSelected("Driveway/Cement Cleaning") || serviceIsSelected("Fence Restoration")) && (
                <div className="RequestQuote-input-row">
                  <InputRadio
                    innerRef={quoteTypeElem}
                    label="Type of Quote:"
                    options={quoteTypes}
                    exclusive={true}
                    selectedIndexes={[quoteType]}
                    onChangeSelected={handleSelectQuoteType}
                  />
                </div>
              )}
              {step === 2 && serviceIsSelected("Deck Restoration") && quoteType === 0 && (
                <>
                  <div className="RequestQuote-input-row">
                    <InputRadio
                      label="Deck Substrate:"
                      options={deckSubstrates}
                      exclusive={true}
                      selectedIndexes={[deckSubstrate]}
                      onChangeSelected={idxs => setDeckSubstrate(idxs[0])}
                    >
                      <InputRadioOption
                        checked={deckSubstrate === deckSubstrates.length}
                        onClick={() => {setDeckSubstrate(deckSubstrates.length)}}
                      >
                          Other&nbsp;
                          <InputText
                            value={deckSubstrateOther}
                            onChange={setDeckSubstrateOther}
                            onClick={() => setDeckSubstrate(deckSubstrates.length)}
                            width="7em"
                            inline={true}
                          />
                      </InputRadioOption>
                    </InputRadio>
                  </div>
                  <div className="RequestQuote-input-row">
                    <InputText
                      label="Deck Area (square feet):"
                      textAlign="right"
                      value={deckArea}
                      onChange={v => {if (!isNaN(v)) setDeckArea(v)}}
                      width="7em"
                    />
                  </div>
                  <div className="RequestQuote-input-row">
                    <InputText
                      label="Railings (linear feet):"
                      textAlign="right"
                      value={railingsLen}
                      onChange={v => {if (!isNaN(v)) setRailingsLen(v)}}
                      width="7em"
                    />
                  </div>
                  <div className="RequestQuote-input-row">
                    <InputDropdown
                      label="Deck Height:"
                      options={deckHeights}
                      selectedIdx={deckHeights.indexOf(deckHeight)}
                      onSelectIdx={i => setDeckHeight(deckHeights[i])}
                    />
                  </div>
                  <div className="RequestQuote-input-row">
                    <InputDropdown
                      label="Is deck currently stained?"
                      options={yesNo}
                      selectedIdx={deckIsStained}
                      onSelectIdx={setDeckIsStained}
                    />
                  </div>
                  {yesNo[deckIsStained] === "Yes" && (
                    <div className="RequestQuote-input-row">
                      <InputText
                        label="What kind of stain? (if you know)"
                        value={deckStainType}
                        onChange={setDeckStainType}
                      />
                    </div>
                  )}
                </>
              )}
              {step === 2 && serviceIsSelected("Driveway/Cement Cleaning") && quoteType === 0 && (
                <div className="RequestQuote-input-row">
                  <InputText
                    label="Area to be Cleaned (square feet):"
                    textAlign="right"
                    value={drivewayArea}
                    onChange={v => {if (!isNaN(v)) setDrivewayArea(v)}}
                    width="7em"
                  />
                </div>
              )}
              {step === 2 && serviceIsSelected("Fence Restoration") && (
                <>
                  {quoteType === 0 && (
                    <div className="RequestQuote-input-row">
                      <InputRadio
                        label="Fence Substrate:"
                        options={fenceSubstrates}
                        exclusive={true}
                        selectedIndexes={[fenceSubstrate]}
                        onChangeSelected={idxs => setFenceSubstrate(idxs[0])}
                      >
                        <InputRadioOption
                          checked={fenceSubstrate === fenceSubstrates.length}
                          onClick={() => setFenceSubstrate(fenceSubstrates.length)}
                        >
                            Other&nbsp;
                            <InputText
                              value={fenceSubstrateOther}
                              onChange={setFenceSubstrateOther}
                              onClick={() => setFenceSubstrate(fenceSubstrates.length)}
                              width="7em"
                              inline={true}
                            />
                        </InputRadioOption>
                      </InputRadio>
                    </div>
                  )}
                  {quoteType === 0 && fenceSubstrate === fenceSubstrates.indexOf("Wood") && (
                    <>
                      <div className="RequestQuote-input-row">
                        <InputRadio
                          label="Fence Service Requested:"
                          options={woodFenceServices}
                          exclusive={true}
                          selectedIndexes={[fenceService]}
                          onChangeSelected={idxs => setFenceService(idxs[0])}
                        >
                          <InputRadioOption
                            checked={fenceService === woodFenceServices.length}
                            onClick={() => setFenceService(woodFenceServices.length)}
                          >
                              Other&nbsp;
                              <InputText
                                value={fenceServiceOther}
                                onChange={setFenceServiceOther}
                                onClick={() => setFenceService(woodFenceServices.length)}
                                width="7em"
                                inline={true}
                              />
                          </InputRadioOption>
                        </InputRadio>
                      </div>
                      <div className="RequestQuote-input-row">
                        <InputDropdown
                          label="Is there stain on the fence currently?"
                          options={yesNo}
                          selectedIdx={fenceIsStained}
                          onSelectIdx={setFenceIsStained}
                        />
                      </div>
                    </>
                  )}
                  {quoteType === 0 && fenceSubstrate === fenceSubstrates.indexOf("Wood") && yesNo[fenceIsStained] === "Yes" && (
                    <div className="RequestQuote-input-row">
                      <InputText
                        label="What kind of stain? (if you know):"
                        value={fenceStainType}
                        onChange={setFenceStainType}
                      />
                    </div>
                  )}
                  {quoteType === 0 && (
                    <>
                      <div className="RequestQuote-input-row">
                        <InputText
                          label="Fence Height (feet):"
                          width="7em"
                          textAlign="right"
                          value={fenceHeight}
                          onChange={v => {if (!isNaN(v)) setFenceHeight(v)}}
                        />
                      </div>
                      <div className="RequestQuote-input-row">
                        <InputText
                          label="Fence Length (feet) :"
                          width="7em"
                          textAlign="right"
                          value={fenceLength}
                          onChange={v => {if (!isNaN(v)) setFenceLength(v)}}
                        />
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
            <div className="RequestQuote-submit-row">
              {step > 0 && <div className="RequestQuote-button">
                <Button
                  outlined={true}
                  rounded
                  onClick={handleBack}
                  color="black"
                >
                  Back
                </Button>
              </div>}
              <div className="RequestQuote-button">
                <Button
                  outlined={true}
                  color="black"
                  rounded
                  onClick={isSubmitStep ? handleSubmit : handleNext}
                  loading={submitDisabled}
                >
                  <div>{isSubmitStep ? "Submit" : "Next"}</div>
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default RequestQuote;
