import React, { useState, useEffect } from 'react';
import logo from './logo.png';
import './App.css';
import { Router, Link } from "@reach/router"

import Button from './Button'
import Home from './Home'
import RequestQuote from './RequestQuote'


const Header = () => {
  const [boxShadow, setBoxShadow] = useState('none');

  useEffect(() => {
    window.onscroll = function() {
      if (window.scrollY > 50) {
        setBoxShadow('0 0 10px #999');
      } else {
        setBoxShadow('none');
      }
    };
  }, []);

  return (
    <header
      className={`
        sticky z-10 top-0 w-full px-4 py-2
        flex items-center justify-between
        bg-white text-white`
      }
      style={{fontSize: 'calc(1rem + 1.2vmin)', boxShadow: boxShadow}}
    >
      <Link to="/" style={{ outline: 'none' }}>
        <img src={logo} className="h-20" alt="logo" />
      </Link>
      <Button noPadding href="https://www.facebook.com/MightyMistClean/">
        <div className="App-contact-us">
          <div className="fa fa-facebook" />
        </div>
      </Button>
    </header>
  )
}



function App() {
  return (
    <div className="w-full min-h-screen text-center text-white bg-gray-300 flex justify-center">
      <div className="w-full max-w-3xl text-center bg-white">

        <Header/>

        <div className="w-full mb-16">
          <Router>
            <Home path="/" />
            <RequestQuote path="quote" />
          </Router>
        </div>
      </div>
    </div>
  );
}

export default App;

/*<div className="App">
      <header className="App-header">
        <Link to="/" style={{ outline: 'none' }}>
          <img src={logo} className="App-logo" alt="logo" />
        </Link>
        <Button noPadding href="https://www.facebook.com/MightyMistClean/">
          <div className="App-contact-us">
            <div className="fa fa-facebook" />
          </div>
        </Button>
      </header>
      <div className="App-body">
        <Router style={{
          display: 'flex', flex: '1', justifyContent: 'center',
        }}>
          <Home path="/" />
          <RequestQuote path="quote" />
        </Router>
      </div>
      </div>*/