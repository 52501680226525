import React from 'react';
import './Button.css';
import Loader from './Loader'

function Button({
  children,
  onClick=() => undefined,
  href=undefined,
  outlined=false,
  rounded=false,
  shadow=false,
  selectable=false,
  draggable=true,
  pointer=true,
  noPadding=false,
  color='',
  loading=false
}) {
  const style = {}
  if (color) {
    if (outlined) {
      style.borderColor = color;
    } else {
      style.backgroundColor = color;
    }
  }
  if (shadow)
    style.boxShadow = '0px 0px 16px rgba(0, 0, 0, .3)'
  if (!selectable)
    style.userSelect = 'none'
  if (pointer)
    style.cursor = 'pointer'
  else
    style.cursor = 'inherit'
  
  return (
    <a
      className={"Button".concat(outlined ? ' Button-outlined' : '', noPadding ? ' Button-no-padding' : '', rounded ? ' Button-rounded' : '')}
      href={href}
      onClick={onClick}
      style={style}
      draggable={draggable ? "true" : "false"}
    >
      {loading ? <Loader color={color} /> : children}
    </a>
  );
} 

export default Button;
