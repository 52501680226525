import React, { useState } from 'react';
import './InputRadioOption.css';
import _ from 'lodash';

function InputRadioOption({ checked, onClick, innerRef, children }) {
  const [id] = useState(_.uniqueId("input_"))

  return (
    <div className="InputRadioOption-row">
      <input
        id={id}
        type="radio"
        checked={checked}
        onChange={() => undefined}
        onClick={onClick}
        ref={innerRef}
      />
      <label
        className="InputRadioOption-label"
        htmlFor={id}
      >
          {children}
      </label>
      <br />
    </div>
  )
}

export default InputRadioOption;
