import React, { useState } from 'react';
import './InputDropdown.css';
import _ from 'lodash';

function InputDropdown({ label, options, selectedIdx, onSelectIdx }) {
  const [id] = useState(_.uniqueId("input_"))

  return (
    <div className="InputDropdown">
      <label htmlFor={id}>{label}&nbsp;</label>
      <select
        className="InputDropdown-select"
        id={id}
        value={selectedIdx.toString()}
        onChange={e => onSelectIdx(parseInt(e.target.value))}
        style={{ color: selectedIdx === 0 ? 'grey' : 'black' }}
      >
        {options.map((v, i) => (
          <option
            className="InputDropdown-option"
            key={i}
            value={i}
            hidden={i === 0}
          >
            {v}
          </option>
        ))}
      </select>
    </div>
  );
}

export default InputDropdown;
