import React from 'react';
import './Home.css';

import Button from './Button'
import Services from './Services'
import Slideshow from './Slideshow'
//import backgroundImg from './background.jpg'


const QuoteButton = ({ navigate }) => (
  <div
    className="fixed bottom-6"
    // 48rem corresponds to max-w-3xl
    style={{ right: 'calc(max(calc(calc(100% - 48rem)/2), 0px) + 1rem)'}}
  >
    <Button
      rounded shadow={true} color="#2d97d3"
      onClick={() => navigate('quote')}
    >
      <p className="mx-16 my-2">Online Quote</p>
    </Button>
  </div>
)

function Home({ navigate }) {
  return (
    <div className="w-full flex justify-center">
      <div className="w-full max-w-3xl">
        <QuoteButton navigate={navigate} />
        <div className="relative w-full">
          <div className="w-full">
            <Slideshow delay={3500}/>
            {/* <img
              src={backgroundImg}
              className="w-full"
              style={{ transform: 'scaleX(-1)'}}
            /> */}
          </div>
          <div
            className={`
              absolute bottom-0 p-4
              text-white text-3xl font-bold text-left
            `}
            style={{ textShadow: '0px 0px 15px black' }}
          >
            <p>Mighty Mist</p>
            <p>Pressure Washing</p>
          </div>
        </div>
        
        <div className={`
          w-full p-4 bg-gradient-to-r from-red-banner to-trans-red-banner
          flex flex-col md:flex-row items-center
        `}>
          <div className="md:flex-1">
            <p className="text-left text-caption">
              Pressure and soft washing in Grand Rapids, Michigan and surrounding
              areas. Give us a call for a free quote!
            </p>
          </div>
          <div className="flex md:flex-1 justify-center">
            <Button
              href="tel:2313070049"
              selectable={true}
              pointer={false}
              draggable={false}
            >
              <p className="text-2xl md:text-4xl font-bold underline">
                (231) 307-0049
              </p>
            </Button>
          </div>
        </div>

        <Services />
      </div>
    </div>
    // <div className="Home">
    //   <p className="Home-text">
    //     Thanks for choosing Mighty Mist. We are a West Michigan based cleaning
    //     company that prides ourselves on excellent service and detail-oriented
    //     work. Send us some info and we'll get you a quote within 24 buisness
    //     hours. Our services include house soft washing, deck restoration,
    //     cement surface cleaning, graffiti removal, and commercial exterior
    //     cleaning projects.
    //   </p>
    //   <div className="Home-button">
    //     <Button outlined={true} onClick={() => navigate('quote')}>
    //       Get a Quote
    //     </Button>
    //   </div>
    // </div>
  );
}

export default Home;
