import React from 'react'

import houseWashImg from './house-wash.webp'
//import roofCleaningImg from './roof-cleaning.webp'
import exteriorCleaningImg from './exterior-cleaning.webp'
import gutterCleaningImg from './gutter-cleaning.webp'
import surfaceCleaningImg from './surface-cleaning.webp'
import deckFenceCleaningImg from './deck-fence-cleaning.webp'

const services = [
    {
        src: houseWashImg,
        title: 'House Washing',
        desc: 'Is your house covered with the unsightly green algae? Spider webs? Dirt? Rust? No problem!'
    },
    // {
    //     src: roofCleaningImg,
    //     title: 'Roof Cleaning',
    //     desc: 'Our roof cleaning process is similar to our house washing process. We use a safe, low pressure cleaning solution that removes black streaks, algae, moss, and lichen.'
    // },
    {
        src: surfaceCleaningImg,
        title: 'Surface Cleaning',
        desc: 'We can clean patios, driveways and sidewalks without causing damage to the concrete surface.'
    },
    {
        src: deckFenceCleaningImg,
        title: 'Deck/Fence Cleaning',
        desc: 'We can clean both composite/vinyl material and wooden decks and fences. Or process for each is slightly different.'
    },
    {
        src: exteriorCleaningImg,
        title: 'Exterior Cleaning',
        desc: 'We offer many different types of exterior cleaning including soft washing, power washing, surface cleaning, roof cleaning and more.'
    },
    {
        src: gutterCleaningImg,
        title: 'Gutter Cleaning',
        desc: 'By cleaning your gutters, you are able to prevent them from overflowing and possibly falling off.',
    }
]


function Service({ src, title, desc }) {
    return (
        <div className="w-full px-4 py-6 border-b-2 flex flex-col items-center text-center text-black" /*style={{ 'box-shadow': '0px 0px 12px rgba(0, 0, 0, 0.1)'}}*/>
            <img src={src} className="w-32" />
            <p className="pt-4 text-xl">{title}</p>
            <p className="pt-4 text-md">{desc}</p>
          </div>
    )
}

function Services() {
    return (
        <div className="w-full p-4 flex flex-col">
          {services.map((service, i) => <Service key={i} {...service} />)}
        </div>
    )
}

export default Services
