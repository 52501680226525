import React from 'react';
import './Loader.css';

function Loader({ color='' }) {
    return (
        <div
            className="Loader"
            style={{borderTopColor: color, borderRightColor: color}}>
        </div> 
    )
}

export default Loader
